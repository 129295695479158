import { Link } from "gatsby"
import React, { CSSProperties } from "react"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

type Props = {
  text: string,
  href?: string,
  onClick?: () => void,
  internal?: boolean,
  icon?: IconProp,
  style?: CSSProperties
}

const Button = (props: Props) => {

  const btn = (<button className="primary-btn" style={props.style} onClick={props.onClick}>
    {
      props.icon ? (
        <FontAwesomeIcon icon={props.icon} className="mr-2"/>
      ) : null
    }
    {props.text}
  </button>)

  if(props.href){
    if(props.internal){
      return (<Link to={props.href}>
        {btn}
      </Link>)
    }
    return (<a href={props.href} target="_blank">
      {btn}
    </a>)
  }

  return btn
}

export default Button