import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const Logo = () => {
  const alt = "Reico Logo"

  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "ReicoLogo.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 200, maxHeight: 115) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Img
      style={{ width: 200, maxWidth: "25vw"}}
      fluid={data.file.childImageSharp.fluid}
      alt={alt}
    />
  )
}

export default Logo