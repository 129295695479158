import React from "react"
import { Col, Container, Row } from "react-bootstrap"

type Props = {
  children: any
}

const Layout = (props: Props) => (
  <Container>
    <Row>
      <Col xs={12} >
        {props.children}
      </Col>
    </Row>
  </Container>
)

export default Layout