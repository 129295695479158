/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"

type MetaProp = JSX.IntrinsicElements['meta']

type Props = {
  title: string,
  description?: string,
  lang?: string,
  meta?: MetaProp[],
  keywords?: string[]
}

const SEO = (props: Props) => {

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )


  const title = props.title  + " | " +  site.siteMetadata?.title
  const description = props.description || site.siteMetadata.description
  const lang = props.lang || "de"
  const meta = props.meta  || []
  const keywords = props.keywords || ["Reico", "Futter", "Futterberaterin", "Vital", "Hund", "Katze", "Tier", "kaufen", "beraten"]

  const defaultMeta: MetaProp[] = [
    {
      name: `description`,
      content: description,
    },
    {
      property: `og:title`,
      content: title,
    },
    {
      property: `og:description`,
      content: description,
    },
    {
      name: "keywords",
      content: keywords.join(","),
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:creator`,
      content: site.siteMetadata?.author || ``,
    },
    {
      name: `twitter:title`,
      content: title,
    },
    {
      name: `twitter:description`,
      content: description,
    },
  ]

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={defaultMeta.concat(meta)}
    />
  )
}

export default SEO
