import React, { useEffect } from "react"
import ReactGA from 'react-ga';

import "bootstrap/dist/css/bootstrap.min.css"
import "../style/fonts.css"
import "../style/style.css"

import { config } from "@fortawesome/fontawesome-svg-core"
import "@fortawesome/fontawesome-svg-core/styles.css"

config.autoAddCss = false

import Footer from "./Footer"
import Header from "./Header"
import Layout from "./Layout"
import SEO from "./SEO"

type Props = {
  title: string,
  description?: string,
  children: any
}

const PageWrapper = (props: Props) => {

  useEffect(() => {

    if(window.location.hostname.endsWith("futternatur.com")){
      console.log("Initializing Google Analytics ...")
      ReactGA.initialize('UA-196595266-1');
    }
    else{
      console.log(
        "Google Analytics not initialized due to wrong domain",
        window.location.hostname
      )
    }
  }, [])

  useEffect(() => {
    if(window.location.hostname.endsWith("futternatur.com")){
      console.log("Send page view to GA ...")
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
    else{
      console.log(
        "Not sending page view to GA due to wrong domain",
        window.location.hostname
      )
    }
  })


  return (
    <div style={{position: "relative", width: "100%", height: "100%", minHeight: "100vh"}}>
      <SEO title={props.title} description={props.description}/>
      <Header />
      <Layout>
        { props.children }
      </Layout>
      <Footer />
    </div>
  )
}

export default PageWrapper