import React from "react"

const ExternalLink = (props: { text: string, href: string, newTab?: boolean }) => {

  const newTab = !!props.newTab

  return (
    <a href={props.href} target={newTab ? "_blank" : undefined} style={{fontWeight: 600, color: "#000"}}>
      {props.text}
    </a>)
}

export default ExternalLink