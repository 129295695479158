import React, {useEffect, useState} from "react"
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import {Col, Row} from "react-bootstrap";
import {Link} from "gatsby";
import Button from "./basic/Button"

const cookieAcceptKey = "accept-cookie-policy-001"

const AcceptCookiePolicy = () => {

  const [hasAccepted, setHasAccepted] = useState(true)

  const onAccept = () => {
    setHasAccepted(true)
    window.localStorage.setItem(cookieAcceptKey, "1")
  }

  useEffect(() => {
    const item = window.localStorage.getItem(cookieAcceptKey)
    if(!item){
      setHasAccepted(false)
    }
  })

  if(hasAccepted) return null

  return (
    <div
      className="p-4"
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        zIndex: 100,
        backgroundColor: "rgba(0,0,0,0.9)",
        color: "rgba(255,255,255,0.9)"
      }}>
      <Row className="align-items-center">
        <Col xs={12} className="mb-4">
          <h5>Cookie-Richtlinie</h5>
          <span>
            Wir verwenden Cookies ausschließlich zu dem Zweck, die Zugriffe auf unsere Webseite zu analysieren.
            Weiter verwenden wir einen lokalen Speicher (HTML 5 Local Storage). <br/>
            Mehr Informationen finden Sie in unserer
            {' '}
            <Link className="link-style" to={"/datenschutz"}>Datenschutzerklärung</Link>.
          </span>
        </Col>
        <Col xs={12} className="text-center">
          <Button text="Akzeptieren"
                  icon={faCheck}
                  style={{backgroundColor: "green"}}
                  onClick={() => {
                    onAccept()
                  }}
          />
        </Col>
      </Row>
    </div>
  )
}

export default AcceptCookiePolicy