import React, { useEffect, useRef, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import ExternalLink from "./basic/ExternalLink"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons"
import { Link } from "gatsby"
import AcceptCookiePolicy from "./AcceptCookiePolicy"
import Button from "./basic/Button"

const Footer = () => {

  const [height, setHeight] = useState(500)
  const footerTarget = useRef<HTMLDivElement>(null)

  const handleResize = () => {
    setHeight(footerTarget.current?.offsetHeight || 500)
  }

  useEffect(() =>
  {
    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  return (
    <footer className="mt-5">
      <div style={{ height: height }} />
      <div
        ref={footerTarget}
        style={{
          position: "absolute",
          left: 0,
          bottom: 0,
          right: 0,
          backgroundColor: "rgba(0,0,0,0.1)"
        }}>

        <Container className="py-4">
          <Row>
            <Col sm={9}>
              <div className="pt-4 mr-4">
                <h5>
                  Kim Schamberger
                </h5>
                <p>
                  Ich bin professionelle Reico Futterberaterin und berate Sie gerne zu artgerechtem Futter und Nahrungsergänzung für Tier und Mensch.
                </p>
                <p>
                  <FontAwesomeIcon icon={faPhone} className="mr-2" /> +49 175 4150950
                </p>
                <p>
                  <FontAwesomeIcon icon={faEnvelope} className="mr-2" /> kim.schamberger@outlook.de
                </p>
                <p>
                  <Button href="https://lucygonewild.com" text="Zu meinem Blog" />
                </p>
              </div>
            </Col>
            <Col sm={3}>
              <div className="pt-4" >
                <Link to="/datenschutz" style={{fontWeight: 700}}>Datenschutz</Link> <br/>
                <Link to="/impressum" style={{fontWeight: 700}}>Impressum</Link> <br/>
                <ExternalLink href="http://shop.lucygonewild.com" text="Reico" newTab/>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <AcceptCookiePolicy />
    </footer>
  )
}

export default Footer