import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const Logo = () => {
  const alt = "Futternatur Logo"

  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "lucy_logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 80, maxHeight: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Img
      style={{ width: "80px", borderRadius: 40, boxShadow: "0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%)"}}
      fluid={data.file.childImageSharp.fluid}
      alt={alt}
    />
  )
}

export default Logo