import React, { useEffect, useState } from "react"
import Logo from "./images/Logo"
import Button from "./basic/Button"
import { Container } from "react-bootstrap"
import { Link } from "gatsby"
import ReicoLogo from "./images/ReicoLogo"

const Header = () => {

  const [isMobile, setIsMobile] = useState(true)

  const onResize = () => {
    setIsMobile(document.body.clientWidth < 800)
  }

  useEffect(() => {
    onResize()
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    }
  }, [])

  return (
    <div style={{backgroundColor: "#fff", boxShadow: "0 3px 6px rgba(0,0,0,0.08), 0 3px 6px rgba(0,0,0,0.16)" }} className="mb-4">
    <Container>
      <header className="d-flex align-items-center justify-content-between py-3">
        <div className="flex-grow-0 mr-sm-4 mr-3 d-none d-sm-block" style={{ width: 80, height: 80}}>
          <Link to="/">
            <Logo />
          </Link>
        </div>
        <div className="flex-grow-1">
          <h1 className="h2 m-0">Futternatur</h1>
          <div className="">Qualitativ hochwertiges und gesundes Tierfutter von Reico</div>
        </div>
        <div className="flex-grow-0" style={{ width: 200, maxWidth: "25vw"}}>
          <Link to="/">
            <ReicoLogo />
          </Link>
        </div>
      </header>
    </Container>
    </div>
  )

}

export default Header